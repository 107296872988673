export default {
  images: (state) => state.images,
  image: (state) => state.image,
  resize: (state) => state.resize,
  selectedStylesCut: (state) => state.selectedStylesCut,
  rotate: (state) => state.rotate,
  texts: (state) => state.texts,
  selectedStylesResize: (state) => state.selectedStylesResize,
  selectedCrop: (state) => state.selectedCrop,
  selectedText: (state) => state.selectedText,
  imagesSeletedByBox: (state) => state.imagesSeletedByBox,
  hover: (state) => state.hover,
  hoverStyles: (state) => state.hoverStyles,
  countClick: (state) => state.countClick,
  quillEditor: (state) => state.quill,
  quillGetSelection: (state) => state.quillGetSelection,
  quillGetLastSelection: (state) => state.quillGetLastSelection,
  quillGetNewSelection: (state) => state.quillGetNewSelection,
  fontsToEditor: (state) => state.fontsToEditor,
  fontSizeList: (state) => state.fontSizeList,
  isActiveToTextEdit: (state) => state.isActiveToTextEdit,
  parrafosQuill: (state) => state.parrafosQuill,
  showFontSizeText: (state) => state.showFontSizeText,
  paragraphsList: (state) => state.paragraphsList,
  isDragging: (state) => state.isDragging,
};
