<template>
  <v-row class="flex-column color-picker">
    <v-col class="px-4 py-4">
      <v-row justify-md="center">
        <v-col>
          <v-color-picker
            v-model="model"
            hide-inputs
            mode="hexa"
            width="248"
            dot-size="18"
            class="vue-color-picker"
          >
          </v-color-picker>
        </v-col>
      </v-row>
      <v-row align-md="center" class="mt-0 px-3 pb-2">
        <v-col md="3" class="py-0 pl-0">
          <div
            class="v-color-picker__dot d-block"
            :style="{ background: value }"
          ></div>
        </v-col>
        <v-col md="6" class="py-0 ps-0">
          <p class="mb-0 text--value">{{ value }}</p>
        </v-col>
        <v-col md="3" class="text-right py-0">
          <v-icon size="13" color="#3C3D41" @click="openEyeDropper()"
            >fas fa-eye-dropper
          </v-icon>
        </v-col>
      </v-row>

      <v-row align-md="center" class="px-3 mb-2 mt-5" v-if="showOpacity">
        <v-col md="12" class="py-0">
          <p class="mb-0 text--primary">Opacity</p>
        </v-col>
        <v-col md="9" class="py-0">
          <v-slider
            max="255"
            min="0"
            v-model="colorOpacity"
            :track-color="'#E9EAEE'"
            hide-details
          ></v-slider>
        </v-col>
        <v-col md="3" class="py-0">
          <p class="mb-0 text--value text-right">{{ percentOpacity }}%</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "IscColorPicker",
  props: {
    value: [String],
    showOpacity: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      colorOpacity: 255,
      percentOpacity: 0,
      colorEyeDropper: null,
    };
  },
  created() {
    this.colorOpacity = this.returnOpacityColor(this.value);
  },
  computed: {
    model: {
      get() {
        const colorSelected = this.value;       
        const opacity =
        this.colorOpacity <= 15
        ? "0" + this.colorOpacity.toString(16)
        : this.colorOpacity.toString(16);
        const currentColor = colorSelected.slice(0, 7) + opacity;
        return currentColor;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("updatecolor")
      },
    },
  },
  methods: {
    returnOpacityColor(color){
      if(color.length > 7){
        let initValue = color.slice(7, 9);
        let decimalNumber = parseInt(initValue, 16);
        return decimalNumber
      }else{
        return parseInt('FF', 16)
      }
    },
    async openEyeDropper() {
      let eyeDropper = new window.EyeDropper();
      const { sRGBHex } = await eyeDropper.open();
      this.value = sRGBHex;
    },
  },
  watch: {
    colorOpacity: {
      immediate: true,
      handler() {
        this.percentOpacity = Math.round((this.colorOpacity * 100) / 255);
      },
    },
  },
};
</script>

<style lang="scss">
p {
  font-size: 12px;
}
.text--value {
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 17px;
  color: #3c3d41;
}
.v-color-picker {
  //   border-radius: 6px;
  align-self: center;
}
.color-picker {
  //   background-color: white;
  width: 240px;
  border-radius: 6px;

  .v-slider {
    margin: 0;
  }
  // padding: 25px 25px 25px 25px;
  .v-color-picker__canvas {
    border-radius: 6px;

    &-dot {
      border: 2px solid #ffffff;
      box-shadow: none;
    }
  }
  .v-color-picker__dot {
    border-radius: 3px;
    width: 34px;
    height: 21px;
    border: thin solid #c5d0e4;
  }
  .v-color-picker__edit .v-btn {
    display: none;
  }
  span {
    display: none;
  }

  //   .isc-slider__colors {
  //     background: linear-gradient(
  //       to right,
  //       #f00 0%,
  //       #ff0 16.66%,
  //       #0f0 33.33%,
  //       #0ff 50%,
  //       #00f 66.66%,
  //       #f0f 83.33%,
  //       #f00 100%
  //     );
  //   }

  .v-color-picker__alpha {
    display: none;
  }

  .v-color-picker__dot {
    display: none;
  }

  .v-color-picker .v-input__slider {
    border-radius: 11px;
  }

  .v-color-picker__preview .v-slider {
    min-height: 18px;
  }

  .v-color-picker__controls {
    padding: 0;
    margin-top: 13px;
    //     position: relative;

    //     .v-color-picker__preview:not(.v-color-picker__preview--hide-alpha)
    //       .v-color-picker__hue {
    //       margin-bottom: 24px;
    //       position: absolute;
    //       top: 13px;
    //       left: 0;
    //       height: 18px;
    //       border-radius: 11px;
    //     }
  }

  .v-color-picker__preview:not(.v-color-picker__preview--hide-alpha)
    .v-color-picker__hue {
    margin: 0;
    margin-bottom: 10px;
  }
}
.vue-color-picker {
  .v-color-picker__preview .v-slider:not(.v-slider--disabled) .v-slider__thumb {
    box-shadow: none;
    border: 2px solid #ffffff !important;
    background: transparent !important;
  }

  .v-slider__thumb {
    height: 16px;
    width: 16px;
  }
}
</style>

<style lang="scss" scoped>
::v-deep .v-slider__thumb {
  cursor: pointer;
  border: 1px solid #fff !important;

  &::before {
    opacity: 0;
  }
}
::v-deep .v-slider__track-fill,
::v-deep .v-slider__track-background {
  border-radius: 9px;
}
::v-deep .v-slider__track-container {
  height: 5px;
}
</style>
