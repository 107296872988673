import { apollo } from "../../../../plugins/vue-apollo";
import {
  CLONE_FOLDER,
  CLONE_ADDON,
  DELETE_FOLDER,
  DELETE_ADDON,
} from "../graphql/mutations";
import {
  FIND_LATEST_ADDONS,
  FIND_FOLDERS_WITH_ADDONS,
  FIND_ADDONS_BY_FOLDER_ID,
  LIST_LEADS_BY_ADDON,
} from "../graphql/query";
import { UPDATE_FOLDER } from "../../../post/folders/graphql/mutations";

export default {
  //** LIST LATEST ADDONS
  FIND_LATEST_ADDONS_BY_TYPE: (
    { commit, dispatch },
    { take, typeUser, clientId }
  ) => {
    apollo
      .query({
        query: FIND_LATEST_ADDONS,
        variables: { take, typeUser, clientId },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { latestAddons } }) => {
        switch (typeUser) {
          case "admin":
            commit("SET_LATEST_ADMIN_ADDONS", latestAddons);
            break;
          case "user":
            commit("SET_LATEST_USER_ADDONS", latestAddons);
            break;
        }
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },
  CLONE_ADDON: async (
    { dispatch, commit, getters },
    { addon, folderId, clientId, responseFunc  }
  ) => {
    await apollo
      .mutate({
        mutation: CLONE_ADDON,
        variables: { addon },
        update: (cache, { data: { cloneAddon } }) => {
          if (folderId) {
            const data = getters.foldersWithAddons;
            const find = data.data.find((el) => el.id == folderId);
            if (find) {
              find.items.unshift(cloneAddon);
              commit("SET_FOLDERS_WITH_ADDONS", data);
            }
          }
        },
      })
      .then(() => {
        dispatch("FIND_LATEST_ADDONS_BY_TYPE", {
          take: 4,
          typeUser: "user",
          clientId,
        });
        const msgResp =  "Addon cloned successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl CLONE_ADDON", error);
        const msgResp = error.message ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  DELETE_ADDON: async (
    { dispatch, commit, getters },
    { id, folderId, clientId, responseFunc }
  ) => {
    await apollo
      .mutate({
        mutation: DELETE_ADDON,
        variables: { id },
        update: () => {
          if (folderId) {
            // this update folder  with a specific addon
            const data = getters.foldersWithAddons;
            const find = data.data.find((el) => el.id == folderId);
            if (find) {
              const items = find.items.filter((el) => el.id != id);
              find.items = items;
              commit("SET_FOLDERS_WITH_ADDONS", data);
            }
          }
        },
      })
      .then(() => {
        dispatch("FIND_LATEST_ADDONS_BY_TYPE", {
          take: 4,
          typeUser: "user",
          clientId,
        });
        const msgResp =  "Addon deleted successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl DELETE_ADDON", error);
        const msgResp = error.message ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  //* FOLDER
  LIST_ADDONS_WITH_FOLDER: (
    { commit },
    { take, page, limitAddon, clientId }
  ) => {
    apollo
      .query({
        query: FIND_FOLDERS_WITH_ADDONS,
        variables: { take, page, limitAddon, clientId },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { folders } }) => {
        commit("SET_FOLDERS_WITH_ADDONS", folders);
      })
      .catch((err) => {
        console.log(err);
        // dispatch('snackbar/errors', err, { root: true })
      });
  },
  RENAME_FOLDER: async ({ commit, getters }, { id, folder, responseFunc }) => {
    await apollo
      .mutate({
        mutation: UPDATE_FOLDER,
        variables: { id, folder },
        update: (cache, { data: { updateFolder } }) => {
          const data = getters.foldersWithAddons;
          let find = data.data.find((el) => el.id == id);
          if (find) {
            find = { ...find, ...updateFolder };
            const dataNew = {
              count: data.count,
              data: data.data.map((el) => {
                if (el.id == id) {
                  return find;
                }
                return { ...el };
              }),
            };
            commit("SET_FOLDERS_WITH_ADDONS", dataNew);
          }
        },
      })
      .then(() => {
        const msgResp =  "Folder Renamed successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl RENAME_FOLDER", error);
        const msgResp = error.message ?? "Server fails to rename a folder" ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  CLONE_FOLDER: async (context, {folderContext, responseFunc}) => {
    await apollo
      .mutate({
        mutation: CLONE_FOLDER,
        variables: { folderContext, limitAddon: 5 },
      })
      .then(() => {
        const msgResp = "Folder cloned successfully." ; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl CLONE_FOLDER ", error.message);
        const msgResp = error.message ?? "Server fails to clone a folder"; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  DELETE_FOLDER: async (context, { id, responseFunc }) => {
    await apollo
      .mutate({ mutation: DELETE_FOLDER, variables: { id } })
      .then(() => {
        const msgResp = "Folder deleted successfully." ; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })

      .catch((error) => {
        console.log("[Error] in GraphQl DELETE_FOLDER ", error.message);
        const msgResp = error.message ?? "Server fails to delete a folder"; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  FIND_ADDONS_BY_FOLDER: ({ commit }, { page, take, folderId, clientId }) => {
    apollo
      .query({
        query: FIND_ADDONS_BY_FOLDER_ID,
        variables: { page, take, folderId, clientId },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { folders } }) => {
        commit("SET_ADDONS_BY_FOLDER", { folders, folderId });
      })
      .catch((err) => {
        console.log(err);
        // dispatch('snackbar/errors', err, { root: true })
      });
  },
  GET_LEADS_BY_ADDON: async ({ commit }, { addonId, responseFunc} ) => {
    await apollo
      .query({
        query: LIST_LEADS_BY_ADDON,
        variables: { addonId },
      })
      .then(({ data: { listLeads } }) => {
        if (listLeads.length == 0) {
          commit("SET_LEADS", []);
          const msgResp = "This addon does not have any data yet." ; 
          const typeResp = "error";
          return responseFunc(msgResp, typeResp)
        } else {
          commit("SET_LEADS", listLeads);
        }
      })
      .catch((error) => {
        console.log("[Error] in GraphQl GET_LEADS_BY_ADDON ", error.message);
        const msgResp = error.message ?? "Server fails to get a lead addon"; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
};
