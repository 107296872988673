export default {
  ADD_DRAG_IMAGE: (state, image) => {
    state.images = [...state.images, image];
  },
  ADD_CLICK_IMAGE: (state, image) => {
    state.images = [...state.images, image];
  },
  SET_IMAGES: (state, images) => {
    state.images = images;
  },
  SET_TEXTS: (state, texts) => {
    state.texts = texts;
  },
  SET_HOVER: (state, hover) => {
    state.hover = hover;
  },
  SET_HOVER_STYLES_SELECTED: (state, hoverStyles) => {
    state.hoverStyles = hoverStyles;
  },
  DRAG_IMAGE_START: (state, image) => {
    state.image = image;
  },
  DRAG_IMAGE_END: (state, image) => {
    state.image = image;
  },
  UPDATE_IMAGE: (state, images) => {
    state.images = [...images];
  },
  UPDATE_IMAGES_SELECTED_BOX: (state, imagesSeletedByBox) => {
    state.imagesSeletedByBox = [...imagesSeletedByBox];
  },
  EVERY_RESIZE: (state, resize) => {
    state.resize = resize;
  },
  EVERY_ROTATE: (state, rotate) => {
    state.rotate = rotate;
  },
  SET_STYLES_SELECTED: (state, styles) => {
    state.selectedStylesCut = styles;
  },
  REMOVE_STYLES_SELECTED: (state) => {
    state.selectedStylesCut = null;
    //state.selectedStylesResize = null
  },
  REDO_UNDO_POST: (state, images) => {
    state.images = images;
  },
  SET_STYLES_RESIZE: (
    state,
    {
      width,
      height,
      transform: {
        translate: { x, y },
        rotate
      },
    }
  ) => {
    state.selectedStylesResize = {
      ...state.selectedStylesResize,
      width,
      height,
      transform: {
        ...state.selectedStylesResize.transform,
        translate: {
          x,
          y,
        },
        rotate: !isNaN(rotate) ? rotate : state.selectedStylesResize.transform.rotate
      },
    };
  },
  SET_HEIGHT_STYLES_RESIZE: (
    state,
    height,
  ) => {
    state.selectedStylesResize = {
      ...state.selectedStylesResize,
      height,
    };
  },
  SET_WIDTH_STYLES_RESIZE: (
    state,
    width,
  ) => {
    state.selectedStylesResize = {
      ...state.selectedStylesResize,
      width,
    };
  },
  SET_ROTATE: (state, rotate) => {
    state.selectedStylesResize = {
      ...state.selectedStylesResize,
      transform: {
        ...state.selectedStylesResize.transform,
        rotate,
      },
    };
  },
  SET_SELECTED_CROP: (state, selectedCrop) => {
    state.selectedCrop = selectedCrop;
  },
  SET_SELECTED_TEXT: (state, selectedText) => {
    state.selectedText = selectedText;
  },
  SET_TEXT_CLICK_COUNT: (state, count) => {
    state.countClick = count;
  },
  SET_QUILL_INSTANCE(state, instance) {
    state.quill = instance;
  },
  SET_TEXT_CLICK_COUNT: (state, count) => {
    state.clickCount = count;
  },
  SET_QUILL_GET_SELECTION: (state, value) => {
    state.quillGetSelection = value;
  },
  SET_QUILL_LAST_SELECTION: (state, value) => {
    state.quillGetLastSelection = value;
  },
  SET_QUILL_NEW_SELECTION: (state, value) => {
    state.quillGetNewSelection = value;
  },
  SET_FONT_SIZE: (state, value) => {
    state.fontSizeList = value;
  },
  SET_IS_ACTIVE_TO_TEXTEDIT: (state, value) => {
    state.isActiveToTextEdit = value;
  },
  SET_SHOWFONTSIZE_TEXT: (state, value) => {
    state.showFontSizeText = value;
  },
  SET_PARRAFOS_QUILL: (state, value) => {
    state.paragraphsList = value;
  },
  SET_IS_DRAGGING: (state, value) => {
    state.isDragging = value;
  },
};
