import Vue from "vue";
import VueApollo from "vue-apollo";
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";

// Install the vue plugins
Vue.use(VueApollo);

// Http endpoint
let httpEndpoint;

if (process.env.NODE_ENV === "production") {
  httpEndpoint =
    "https://jrfg4zlyb8.execute-api.us-east-1.amazonaws.com/dev/graphql";
} else {
  httpEndpoint = "http://localhost:5000/dev/graphql";
}

// Config
const defaultOptions = {
  httpEndpoint,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "cache-and-network",
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "backgrounds: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });
}

export const apollo = createProvider().defaultClient;
