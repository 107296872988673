export default {
  images: [],
  image: {},
  resize: {},
  rotate: null,
  isDragging: false,
  selectedStylesCut: null,
  selectedStylesResize: {
    width: 0,
    height: 0,
    transform: {
      translate: {
        x: 0,
        y: 0,
      },
      rotate: 0,
    },
  },
  hover: false,
  hoverStyles: {
    width: 0,
    height: 0,
    transform: {
      translate: {
        x: 0,
        y: 0,
      },
      rotate: 0,
    },
    uuid: null
  },
  texts: [],
  paragraphsList:[],
  selectedCrop: {
    positionBackground: {
      x: 0,
      y: 0,
    },
  },
  selectedText: null,
  imagesSeletedByBox: [],
  parrafosQuill: [],
  countClick: 0,
  quill: null,
  isActiveToTextEdit: false,
  showFontSizeText: false,
  quillGetSelection: null,
  quillGetLastSelection: null,
  quillGetNewSelection: null,
  fontsToEditor: [
    "AndadaPro",
    "Arial",
    "Antonio",
    "Arapey",
    "Archivo",
    "Assistant",
    "Barlow",
    "Basic",
    "BeVietnam",
    "Bilbo",
    "BlackHanSans",
    "Blinker",
    "Boogaloo",
    "BowlbyOne",
    "BubblerOne",
    "Bungee",
    "BebasNeue",
    "Economica",
    "Baskerville",
    "SeaweedScript",
    "Roboto",
    "Phosphate",
    "AbhayaLibre",
  ],
  fontSizeList:[
    '12px',
    '15px',
    '18px',
    '19px',
    '20px',
    '24px',
    '25px',
    '27px',
    '32px',
    '42px',
    '47px',
    '48px',
    '50px',
    '55px',
    '63px',
    '65px',
    '110px',
  ]
};
