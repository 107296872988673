import { apollo } from "../../../../plugins/vue-apollo";
import { CLONE_POST, DELETE_POST } from "../../../home/post/graphql/mutations";
import { CREATE_FOLDER } from "../graphql/mutations";
import {
  FIND_FOLDERS,
  FIND_POSTS_BY_FOLDER,
  // FIND_POSTS_BY_FOLDER_PAGINATE,
  FIND_POSTS_BY_FOLDER_PAGINATE_ORDER,
  GET_FOLDER,
} from "../graphql/query";
import { messagePopUp } from "../../../../utils/utils";

export default {
  FIND: ({ commit }, { type, clientId, responseFunc }) => {
    apollo
      .query({
        query: FIND_FOLDERS,
        variables: { type, clientId },
      })
      .then(({ data: { folders } }) => {
        commit("SET_FOLDERS", folders);
      })
      .catch((error) => {
        console.log("[Error] in GraphQl FIND (folder)", error.message);
        const msgResp = error?.message ?? "Server fails to find folders" ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  CREATE: ({ commit }, { folder, responseFunc }) => {
    const clientId = folder.clientId;
    apollo
      .mutate({
        mutation: CREATE_FOLDER,
        variables: { folder },
        update: (cache, { data: { folder } }) => {

          const data = cache.readQuery({
            query: FIND_FOLDERS,
            variables: { type: folder.type, clientId },
          });

          data.folders.unshift(folder);
          cache.writeQuery({
            query: FIND_FOLDERS,
            variables: { type: folder.type, clientId },
            data: { folders: data.folders },
          });
          commit("SET_FOLDERS", data.folders);
        },
      })
      .then(() => {
        const msgResp =  "Folder added successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl CREATE(Folder)", error.message);
        const msgResp = error?.message ?? "Server fails to create a folder." ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },

  FIND_POSTS_BY_FOLDER: ({ commit }, { folderId }) => {
    apollo
      .query({
        query: FIND_POSTS_BY_FOLDER,
        variables: { folderId },
      })
      .then(({ data: { items } }) => {
        commit("SET_POSTS_BY_FOLDER", items);
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  FIND_POSTS_BY_FOLDER_PAGINATE_ORDER: (
    { commit, getters },
    { folderId, take, page, order }
  ) => {
    apollo
      .query({
        query: FIND_POSTS_BY_FOLDER_PAGINATE_ORDER,
        variables: { folderId, take, page, order },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { items } }) => {
        commit("SET_MORE_POST", items.length == take);
        if (page > 1) {
          commit("SET_POSTS_BY_FOLDER", getters.postsByFolder.concat(items));
        } else {
          commit("SET_POSTS_BY_FOLDER", items);
        }
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  GET_FOLDER: async ({ commit }, id) => {
    apollo
      .query({
        query: GET_FOLDER,
        variables: { id },
      })
      .then(({ data: { getFolder } }) => {
        commit("SET_FOLDER_VIEW", getFolder);
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  CLONE_POST: ({ commit }, {id, responseFunc}) => {
    apollo
      .mutate({
        mutation: CLONE_POST,
        variables: { id },
      })
      .then(({ data: { clonePost } }) => {
        commit("ADD_CLONE_POST_FOLDER", clonePost);
        const msgResp =  "Post cloned successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl CLONE_POST", error);
        const msgResp = error.message ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  DELETE_POST: ({ dispatch }, { id, responseFunc }) => {
    apollo
      .mutate({
        mutation: DELETE_POST,
        variables: { id },
      })
      .then(() => {
        dispatch("IS_DELETE_POST", true);
        const msgResp =  "Post deleted successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl DELETE_POST", error);
        dispatch("IS_DELETE_POST", false);
        const msgResp = error?.message ?? "Server fails to delete a post" ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  IS_DELETE_POST: ({commit}, isDelete) => {
    commit("SET_IS_DELETE_POST", isDelete);
  },
  REMOVE_POST_FROM_FOLDER: ({ commit }, id) => {
    commit("DELETE_POST_FOLDER", id);
  }

};
