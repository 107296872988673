import "regenerator-runtime/runtime";
import "./set-public-path";
import "./global.scss";
import Vuetify from "./plugins/vuetify";
import Store from "./store/index";
// You can export Vue components from this file and import them into your microfrontends
export { mapActions, mapGetters } from "vuex";
//*COMMON
export { default as BaseInput } from "./components/inputs/BaseInput.vue";
import VueSweetalert2 from 'vue-sweetalert2';
import vuelidate from 'vuelidate'

//*QUILL FUNCTIONS
import quillConfig from "./store/post/drag/functions/quillFunctions";
export const quillFunctions = quillConfig;

//export { default as VueHtml2Canvas } from 'vue-html2canvas';
export { default as SaveModal } from "./components/dialogs/SaveModal.vue";
export { default as IscModal } from "./components/dialogs/IscModal.vue";
export { default as IscModalAlert } from "./components/dialogs/IscModalAlert.vue";
export { default as AddFolderModal } from "./components/dialogs/AddFolderModal.vue";
export { default as IscColorPicker } from "./components/inputs/IscColorPicker.vue";
export {default as CpProgressLinear } from "./components/inputs/CpProgressLinear.vue"
export { default as SelectInput} from "./components/inputs/SelectInput.vue"
import * as htmlToImage from 'html-to-image';


//** ADDON COMPONENTS */
export { default as PreviewLogo } from "./components/addon/preview/PreviewLogo.vue";
export { default as PreviewBackground } from "./components/addon/preview/PreviewBackground.vue";
export { default as ConfirmationMessage } from "./components/addon/message/ConfirmationMessage.vue";
export { default as PreviewQuestions } from "./components/addon/preview/PreviewQuestions.vue";

//** PUBLISH COMPONENTS */
export { default as DsButtonOutlined} from "./components/buttons/DsButtonOutlined.vue"
export { default as CloseButton} from "./components/buttons/CloseButton.vue"
export { default as DesignButtonSkyblue} from "./components/buttons/DesignButtonSkyblue.vue"
export { default as SelectDesign } from "./components/inputs/SelectDesign.vue"

//** MODAL SCREEN SIZE*//
export {default as ScreensizeModal} from "./components/modal/ScreensizeModal.vue"

//** MIXINS */
import { alertSetting, handleRespMixin, signedUrlUploadMixin,uploadFunctionsMixin } from "./mixins/index";
/* shared */
export { default as moment } from "moment";
export { default as Swal } from "sweetalert2";
export { LinearGradient } from "vue-gpickr";

export {
    generateKeywords,
    getKeywordsArray,
    getArrayString,
    transformThumbnails,
    transformStringDate,
    messagePopUp,
    compareTwoArrays,
    replicateMissingObjects,
    changeDomainImage,
		validationUrl,
    checkIfImageExists
} from "./utils/utils";

export const store = Store;
export const vuesweetalert2 = VueSweetalert2;
//export const Parchment = Parchment;
export const Vuelidate = vuelidate;
export const vuetify = Vuetify;
export const HtmlToImage = htmlToImage;
export const AlertSetting  = alertSetting;
export const HandleRespMixin  = handleRespMixin;
export const SignedUrlUploadMixin  = signedUrlUploadMixin;
export const UploadFunctionsMixin  = uploadFunctionsMixin;