export default {
  SET_LATEST_USER_ADDONS: (state, latestAddons) => {
    state.latestUserAddons = latestAddons;
  },
  SET_LATEST_ADMIN_ADDONS: (state, latestAddons) => {
    state.latestAdminAddons = latestAddons;
  },
  SET_FOLDERS_WITH_ADDONS: (state, folders) => {
    state.foldersWithAddons = folders;
  },
  SET_ADDONS_BY_FOLDER: (state, { folders, folderId }) => {
    // validation if it exists duplicates Ids.
    const { items } = state.foldersWithAddons.data.find(
      (el) => el.id === folderId
    );
    let isId = false;
    for (let i = 0; i < folders.length && !isId; i++) {
      isId = items.some((el) => {
        return el.id === folders[0].id;
      });
    }
    if (!isId) {
      state.foldersWithAddons.data.forEach((el) => {
        if (el.id === folderId) {
          folders.forEach((item) => {
            el.items.push(item);
          });
        }
      });
    }
  },
  SET_LEADS: (state, leads) => {
    state.leads = leads;
  },
};
