export default {
  SET_TEMPLATES: (state, templates) => {
    state.templates = [ ...templates ];
  },
  SET_TEMPLATES_BY_KEYWORD: (state, templatesByKeywords) => {
    state.templatesByKeywords = [...templatesByKeywords];
  },
  RESET_TEMPLATES_BY_KEYWORDS: (state) => {
    state.templatesByKeywords = [];
  },
  // post
  SET_TEMPLATE: (state, template) => {
    state.template = template;
  },
  SET_POSTS_BY_USER: (state, posts) => {
    state.postsUser = posts;
  },
  /* RESET: state => {
    state.read = {};
  }, */
};
