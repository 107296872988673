const actions = {
    addTitle({ commit }, title) {
        commit("SET_TITLE_PAGE", title);
    },
    addDateRange({ commit }, dateRange) {
        commit("SET_DATE_RANGE", dateRange);
    },
    setContextBoard({ commit }, contextBoard) {
        commit("ADD_CONTEXT_BOARD", contextBoard);
    },
    changeYourBoard({ commit }, yourBoard) {
        commit("CHANGE_YOUR_BOARD", yourBoard);
    },
    disabledMenuItem({ commit }, state) {
        commit("CHANGE_STATE_MENUSIDEBAR", state);
    },
    removeContextBoard({ commit }) {
        commit("REMOVE_CONTEXT_BOARD");
    },
    setDatePickerActive({ commit }, value) {
        commit("CHANGE_ACTIVE_DATEPICKER", value);
    },
    openModalWord(contex) {
        contex.commit('OPEN_MODAL_WORD')
    },
    closeModalWord(contex) {
        contex.commit('CLOSE_MODAL_WORD')
    },
    setName({ commit }, value) {
        commit("SET_NAME", value);
    },
    setToken({ commit }, value) {
        commit("SET_TOKEN", value);
    },
    setRefresh({ commit }, value) {
        commit("SET_REFRESH", value);
    },
    setObjUsuario({ commit }, obj) {
        commit("SET_OBJ_USUARIO", obj);
    }
}

export default actions;
