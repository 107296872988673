import {
  CLONE_FOLDER,
  CLONE_POST,
  DELETE_FOLDER,
  DELETE_POST,
} from "../graphql/mutations";
import {
  FIND_BY_KEYWORDS,
  FIND_FOLDERS_BY_TYPE,
  FIND_FOLDERS_WITH_POSTS,
  FIND_LATEST_POSTS,
} from "../graphql/query";
import { apollo } from "../../../../plugins/vue-apollo";
import { UPDATE_FOLDER } from "../../../post/folders/graphql/mutations";

export default {
  //** LIST FOLDERS  ADDONS ADMIN
  FIND_ADDONS_BY_TYPE: ({ commit, dispatch }, { type, take, page }) => {
    apollo
      .query({
        query: FIND_FOLDERS_BY_TYPE,
        variables: { type, take, page },
      })
      .then(({ data: { items } }) => {
        switch (type) {
          case "contest":
            commit("SET_FOLDERS_BY_CONTEST", items);
            break;
          case "survey":
            commit("SET_FOLDERS_BY_SURVEY", items);
            break;
          case "promo":
            commit("SET_FOLDERS_BY_PROMO", items);
            break;
          case "audience":
            commit("SET_FOLDERS_BY_AUDIENCE", items);
            break;
        }
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },
  FIND_ADDONS_BY_KEYWORDS: ({ commit, dispatch }, { keywords }) => {
    apollo
      .query({
        query: FIND_BY_KEYWORDS,
        variables: { keywords },
      })
      .then(({ data: { items } }) => {
        commit("FIND_ADDONS_BY_KEYWORDS", items);
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },
  FIND_LATEST_POSTS_BY_TYPE: (
    { commit, dispatch },
    { take, typeUser, clientId }
  ) => {
    apollo
      .query({
        query: FIND_LATEST_POSTS,
        variables: { take, typeUser, clientId },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { items } }) => {
        commit("SET_LATEST_POSTS", items);
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },
  CLONE_POST: async ({ dispatch, commit, getters }, { id, folderId, clientId, responseFunc }) => {
    await apollo
    .mutate({
      mutation: CLONE_POST,
      variables: { id },
      update: (cache, { data: { clonePost } }) => {
        if (folderId) {
          const data = getters.foldersWithPosts;
          const find = data.data.find((el) => el.id == folderId);
          if (find) {
            find.items.unshift(clonePost);
            
            commit("SET_FOLDERS_WITH_POSTS", data);
          }
        }
      },
    })
    .then(() => {
        dispatch("FIND_LATEST_POSTS_BY_TYPE", { take: 4, typeUser: "user", clientId});

        const msgResp =  "Post cloned successfully."; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl CLONE_POST", error);
        const msgResp = error?.message  ?? "Server fails to clone a post."; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  DELETE_POST: async ({ dispatch, commit, getters }, { id, folderId, clientId, responseFunc  }) => {
    await apollo
      .mutate({
        mutation: DELETE_POST,
        variables: { id },
        update: () => {
          if (folderId) {
            const data = getters.foldersWithPosts;
            const find = data.data.find((el) => el.id === folderId);
            if (find) {
              find.items = find.items.filter((el) => el.id !== id);

              commit("SET_FOLDERS_WITH_POSTS", data);
            }
          }
        },
      })
      .then(() => {
        dispatch("FIND_LATEST_POSTS_BY_TYPE", { take: 4, typeUser: "user", clientId });
        const msgResp =  "Post deleted successfully."; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl DELETE_POST", error);
        const msgResp = error?.message  ?? "Server fails to delete a post."; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  LIST_POSTS_WITH_FOLDER: ({ commit }, { take, page, limitPost, clientId }) => {
    apollo
      .query({
        query: FIND_FOLDERS_WITH_POSTS,
        variables: { take, page, limitPost, clientId },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { folders } }) => {
        commit("SET_FOLDERS_WITH_POSTS", folders);
      })
      .catch((err) => {
        console.log(err);
        // dispatch('snackbar/errors', err, { root: true })
      });
  },
  RENAME_FOLDER: async ({ commit, getters }, { id, folder, responseFunc  }) => {
    await apollo
      .mutate({
        mutation: UPDATE_FOLDER,
        variables: { id, folder },
        update: (cache, { data: { updateFolder } }) => {
          const data = getters.foldersWithPosts;
          let find = data.data.find((el) => el.id === id);
          if (find) {
            find = { ...find, ...updateFolder };
            const dataNew = {
              count: data.count,
              data: data.data.map((el) => {
                if (el.id === id) {
                  return find;
                }
                return { ...el };
              }),
            };
            commit("SET_FOLDERS_WITH_POSTS", dataNew);
          }
        },
      })
      .then(() => {
        const msgResp =  "Folder Renamed successfully."; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl RENAME_FOLDER", error);
        const msgResp = error?.message  ?? "Server fails to rename a folder."; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  CLONE_FOLDER: async (context, { folderContext,  responseFunc}) => {
    await apollo
      .mutate({
        mutation: CLONE_FOLDER,
        variables: { folderContext, limitPost: 5 },
      })
      .then(() => {
        const msgResp =  "Folder cloned successfully."; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl CLONE_FOLDER", error);
        const msgResp = error?.message  ?? "Server fails to clone a folder."; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  DELETE_FOLDER: async (context, { id, responseFunc }) => {
    await apollo
      .mutate({ mutation: DELETE_FOLDER, variables: { id } })
      .then(() => {
        const msgResp =  "Folder deleted successfully."; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl DELETE_FOLDER", error);
        const msgResp = error?.message  ?? "Server fails to delete a folder."; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
};
