import { default as axios } from "axios";
axios.defaults.showLoader = true;
//* DEV URLS
//const apiUploadFile = "http://localhost:3000/dev";
//* PROD URLS
const apiUploadFile = "https://qqrsqtegxl.execute-api.us-east-1.amazonaws.com/dev";

export const uploadImage = async (formData) => {
  return await axios.post(`${apiUploadFile}/screenshot`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
};
//* GET SIGNED_URL AND VALIDATION PLAN
export const getSignedUrl = async (payload, clientId, sizeKb, index, dispatch) => {

  return await axios.post(`${apiUploadFile}/signedUrl`, payload, {
    params: {
      clientId,
      sizeKb,
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
  .catch((error) => {
    console.log(
      "[Error] in function getSignedUrl (Service) ",
      error.response.data.message
    );
    dispatch("ERROR_UPLOAD", {
      error: true,
      index,
      msgError: error?.response?.data.message ?? "Server fails",
    });
    throw error
  });
};
//*Important: we upload file with AWS S# URL
export const uploadFileBySignedUrlS3 = async (urlS3, file, index, dispatch) => {
  return await axios
    .put(urlS3, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (e) =>
        dispatch("UPLOAD_PROGRESS", {
          progress: Math.round((e.loaded * 100) / e.total),
          index,
        }),
    })
    .catch((error) => {
      console.log(
        "[Error] in function MAIN uploadFile (Service) ",
        error.response.data.message
      );
      dispatch("ERROR_UPLOAD", {
        error: true,
        index,
        msgError: error?.response?.data.message ?? "Server fails",
      });
      throw error;
    });
};
export const processUploadThumbnailsImage = async ({fileKey, contentType, index, dispatch}) => {
  return await axios.post(`${apiUploadFile}/upload-thumbnails-image`, { fileKey, contentType }, {
    headers: {
      "Content-Type": "application/json",
    },
  })
  .catch((error) => {
    console.log(
      "[Error] in function processUploadThumbnailsImage (Service) ",
      error.response.data.message
    );
    dispatch("ERROR_UPLOAD", {
      error: true,
      index,
      msgError: error?.response?.data.message ?? "Server fails",
    });
    throw error
  });
};

//* ====== OLD CODE WITH FORMAT UPLOAD =========== 
//* Please, Do not Erase it.
/* export const uploadFile = async (
  formData,
  dispatch,
  index,
  clientId,
  sizeKb
) => {
  return await axios
    .post(api, formData, {
      params: {
        clientId,
        sizeKb,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) =>
        dispatch("UPLOAD_PROGRESS", {
          progress: Math.round((e.loaded * 100) / e.total),
          index,
        }),
    })
    .catch((error) => {
      console.log(
        "[Error] in function MAIN uploadFile (Service) ",
        error.response.data.message
      );
      dispatch("ERROR_UPLOAD", {
        error: true,
        index,
        msgError: error?.response?.data.message ?? "Server fails",
      });
    });
}; */