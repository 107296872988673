export default {
  SET_FOLDERS_BY_ADDON: (state, items) => {
    state.addon = items;
  },
  SET_FOLDERS_BY_CONTEST: (state, items) => {
    items.forEach((el) => {
      state.contest.push(el);
    });
  },
  SET_LATEST_POSTS: (state, posts) => {
    state.latesPosts = posts;
  },
  SET_FOLDERS_WITH_POSTS: (state, folders) => {
    state.foldersWithPosts = folders;
  },
  SET_POSTS_FOLDER: (state, { items, folderId, page }) => {
    state.foldersWithPosts = state.foldersWithPosts.map((el) => {
      if (el.id === folderId) {
        return {
          ...el,
          page,
          items,
        };
      }
      return {
        ...el,
      };
    });
  },
};
