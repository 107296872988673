export default {
  SET_FOLDERS: (state, folders) => {
    state.folders = folders;
  },
  SET_FOLDER_VIEW: (state, folder) => {
    state.folderView = folder;
  },
  SET_POSTS_BY_FOLDER: (state, items) => {
    state.postsByFolder = items;
  },
  SET_MORE_POST: (state, morePost) => {
    state.morePost = morePost;
  },
  ADD_CLONE_POST_FOLDER: (state, clonePost) => {
    state.postsByFolder.unshift(clonePost);
    if (state.morePost) {
      state.postsByFolder.pop();
    }
  },
  DELETE_POST_FOLDER: (state, id) => {
    state.postsByFolder = state.postsByFolder.filter((el) => el.id != id);
  },
  ORDER_POSTS_BY_FOLDER: (state, type) => {
    //type 1 >Last modified, updatedAt; type 2 >alphabetically,name
    state.postsByFolder.sort((a, b) => {
      if (a[type] < b[type]) {
        return -1;
      } else {
        return 1;
      }
    });
  },
  SET_IS_DELETE_POST: (state, isDeletePost) => {
    state.isDeletePost = isDeletePost;
  }
};
